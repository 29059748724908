import { FaAddressBook, FaBusinessTime, FaChartBar, FaChartPie, FaChess, FaCloud, FaFileInvoiceDollar, FaHandshake, FaHeadset, FaHistory, FaHubspot, FaLaptopCode, FaMoneyBillWave, FaPeopleArrows, FaRegNewspaper, FaRoute, FaSellsy, FaShoppingBag, FaStore, FaThermometerHalf, FaToolbox, FaUserCog, FaWhatsapp } from 'react-icons/fa'
import { MdAddChart, MdAreaChart, MdAutorenew, MdBarChart, MdChecklist, MdOutlineAssessment, MdOutlineChat, MdOutlineMarkEmailRead, MdOutlineMiscellaneousServices, MdOutlinePhonelinkRing, MdOutlineSpeaker, MdOutlineViewTimeline, MdPayments, MdPeople, MdSecurity, MdStackedLineChart } from 'react-icons/md'
import { RiChatFollowUpLine, RiCustomerService2Fill, RiGraduationCapLine, RiHandHeartFill, RiPencilRulerFill, RiPresentationLine, RiSurveyLine, RiTeamFill, RiTreeFill } from 'react-icons/ri'
import { FaGaugeSimpleHigh, FaRegAddressCard } from "react-icons/fa6";
import { HiOutlineThumbUp, HiOutlineTicket, HiStatusOnline } from 'react-icons/hi'
import { TbAlertTriangle, TbDoorEnter, TbGauge, TbReportAnalytics, TbReportMoney, TbShoppingCart, TbStarsFilled } from 'react-icons/tb'
import { GiReceiveMoney, GiTeacher,GiGiftOfKnowledge } from 'react-icons/gi'
import { GoChecklist, GoPeople, GoSync } from 'react-icons/go'
import { BsCpu, BsFillCartCheckFill } from 'react-icons/bs'
import { GrMoney, GrOptimize } from 'react-icons/gr'
import { VscServerProcess, VscAzure } from 'react-icons/vsc'
import {CircleFlag} from 'react-circle-flags'
import { LuBrainCircuit, LuDatabaseBackup } from "react-icons/lu";
import { AiOutlineBook, AiOutlineCluster, AiTwotoneFunnelPlot } from "react-icons/ai";
import { SiAngular, SiBaremetrics, SiDatabricks, SiMoleculer } from 'react-icons/si'
import { BiListCheck, BiTransferAlt } from 'react-icons/bi'
import { IoAddSharp, IoBalloonOutline, IoBarbellSharp, IoCalendarOutline, IoChatbubbles, IoMagnet, IoRecordingOutline } from "react-icons/io5";
import { PiBatteryHighFill, PiDetectiveFill } from "react-icons/pi";
export const SearchIcons=(putIcon)=>{

    
    const iconos={
        'Customer Success':<FaHeadset/>,
        'Nomina Electronica':<MdPayments/>,
        'Facturación Electronica':<FaFileInvoiceDollar/>,
        'Nube':<FaCloud/>,
        'Worforce Management':"",
        'Siignal':<HiStatusOnline />,
        'Renewals Latam':<GoSync />,
        'Onboarding y Training':"",
        'Onboarding':<TbDoorEnter />,
        'Training':<GiTeacher />,
        'TCN':<HiOutlineTicket />,
        'Operaciones Inboud':"",
        'Ops Tech':<BsCpu />,
        'Customer General':<RiCustomerService2Fill />,
        'FCR Y CSAT Customer':<GoChecklist />,
        'Históricos':<FaHistory/>,
        'IVR Project':<MdOutlinePhonelinkRing />,
        'Directores':<MdOutlineMiscellaneousServices />,
        'Otras Operaciones':"",
        'Whatsapp':<FaWhatsapp/>,
        'Fundación Siigo':<RiHandHeartFill />,
        'Engagement':"",
        'Finanzas':<FaMoneyBillWave/>,
        'Reportes Generales':"",
        'Informes Generales':<FaChartBar/>,
        'Renewals':<MdAutorenew/>,
        'Productividad':<TbGauge/>,
        'Compras':"",
        'Procesos De Compras':<TbShoppingCart/>,
        'AKKR y BOARD':"",
        'Monthly AKKR':<TbReportMoney/>,
        'Board Of Directors':<RiTeamFill/>,
        'Reporte por País':"",
        'Colombia':<CircleFlag countryCode='co' height='40'/>,
        'Ecuador':<CircleFlag countryCode='ec' height='40'/>,
        'México':<CircleFlag countryCode='mx' height='40'/>,
        'Mexico':<CircleFlag countryCode='mx' height='40'/>,
        'Uruguay':<CircleFlag countryCode='uy' height='40'/>,
        'Chile':<CircleFlag countryCode='cl' height='40'/>,
        'Panel Market':<FaStore/>,
        'Especialista Ecuador':<PiDetectiveFill/>,
        'Panel Adherencia':<PiBatteryHighFill/>,
        'Transferencias':<BiTransferAlt/>,
        'Administración':<FaUserCog/>,
        'Riesgos':<TbAlertTriangle/>,
        'Gestión de Calidad':<TbAlertTriangle/>,
        'Culture and Learning':<FaPeopleArrows/>,
        'People Management':<FaThermometerHalf/>,
        'Talent Acquisition':<FaAddressBook/>,
        'Learning y Development':<FaAddressBook/>,
        'HSE y Workplace':<FaAddressBook/>,
        'Culture':"",
        'Internal Communications':<FaThermometerHalf/>,
        'Wellness':<FaAddressBook/>,
        'Producto':<RiPencilRulerFill/>,
        'Generales':"",
        'KPIs Producto':<TbGauge/>,
        'Comercial':<FaShoppingBag/>,
        'Venta Nueva':"",
        'Ejecución Comercial':<FaThermometerHalf/>,
        'Seguimientos Comerciales':<FaHistory/>,
        'Procesos Comerciales':<GrOptimize/>,
        'Informes Comerciales':<MdPeople/>,
        'Venta Cruzada':"",
        'Rx Clientes':<FaAddressBook/>,
        'Perfilamiento':"",
        'Reports':<MdBarChart/>,
        'Reportes por País':"",
        'Marketing':<FaHubspot/>,
        'Email Marketing':<MdOutlineMarkEmailRead/>,
        'Comunicaciones Clientes':<MdOutlineChat/>,
        'Ecommerce':<BsFillCartCheckFill/>,
        'Tech':<FaLaptopCode/>,
        'Siigo home':<SiAngular/>,
        'Back Office':<FaToolbox/>,
        'Ads-Ons':<MdAddChart/>,
        'Infraestructure':"",
        'Costos-Azure':<VscAzure/>,
        'SecOps':<MdSecurity/>,
        'Equipo':"",
        'Estrategia':<FaChess/>,
        'Alianzas':"",
        'Alianzas Actuales':<FaHandshake />,
        'DataMart Alianzas':<FaStore />,
        'Proyectos':<BiListCheck />,
        'Investigación de Mercados':<FaChartPie/>,
        'Data Analytics':<MdAreaChart/>,
        'Contador':<TbReportMoney />,
        'Data Science':"",
        'Segmentación Clientes':<AiOutlineCluster />,
        'Data Engineering':"",
        'Time Reporting':<MdChecklist />,
        'Time Reporting Leadership':<MdChecklist />,
        'Data Processing':<SiDatabricks />,
        'Reports Help Desk':<MdOutlineSpeaker />,
        'Synergy':<VscServerProcess/>,
        'Cartera':<GiReceiveMoney />,
        'Renovaciones':<TbReportMoney />,
        'Auditoría y SGC':<MdOutlineViewTimeline />,
        'Reportes por País ':<MdOutlineViewTimeline />,
        'add':<IoAddSharp/>,
        'Valoracion Top Performance':<TbStarsFilled />,
        'Comunicaciones':<FaRegNewspaper />,
        'ATC':<MdStackedLineChart/>,
        'Story Points':<IoChatbubbles/>,
        'Interacción Cliente':<HiOutlineThumbUp />,
        'Operación':<IoBalloonOutline/>,
        'Día de Operación':<IoCalendarOutline />,
        'Entrenamiento y Valoraciones':<IoBarbellSharp />,
        'Ecosistemas':<RiTreeFill />,
        'NPS':<TbReportAnalytics />,
        'TNB':<RiPresentationLine />,
        'Siigo Big Numbers':<FaChartBar/>,
        'Core':<FaBusinessTime/>,
        'Game Plan':<FaRoute/>,
        'Board Meeting':<RiTeamFill/>,
        'POS':<AiTwotoneFunnelPlot />,
        'Userpilot':<FaGaugeSimpleHigh />,
        'Pautas':<FaRegAddressCard />,
        'Cotizaciones Abiertas LATAM':<GrMoney />,
        'Latam':<LuDatabaseBackup />,
        'Induccion Comercial':<LuBrainCircuit />,
        'AKKR Métricas':<SiBaremetrics />,
        'People':<GoPeople />,
        'Grabaciones Five9':<IoRecordingOutline />,
        'Proyecto distribuidores':<FaSellsy />,
        'Encuesta':<RiSurveyLine />,
        'Divulgación Académica':<RiGraduationCapLine />,
        'Lead Tracer':<SiMoleculer />,
        'Inbound & Content':<IoMagnet />,
        'Talent':<GiGiftOfKnowledge />,
        'Seguimientos':<RiChatFollowUpLine />,
        'Default':<AiOutlineBook/>
    }

    if (iconos[putIcon]) {
        return iconos[putIcon]
    } else {
        return iconos["Default"]
    }
    

}
